import React, {Component} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import {withLocalize, Translate} from 'react-localize-redux';
import {Redirect, withRouter} from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {Icon, Paper} from "@material-ui/core";
import log from "utils/logger";
import connect from "react-redux/es/connect/connect";
import * as Actions from "redux/actions";
import Header from "containers/header/Header";
import Ticket from 'containers/ticket/Ticket';
import LoadingOverlay from 'react-loading-overlay';
import NoContent from "../../nocontent/NoContent";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Textfit} from "react-textfit";
import * as Helper from "../../../utils/Helper";
import CardContent from "../../ticket/Ticket";
import moment from "moment";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
const mapStateToProps = (state, props) => {
    return {
        session: state.session,
        target: state.target,
        tickets: state.tickets,
        lockerId: state.lockerId,

        ticketConnection: state.ticketConnection,
        ticketCancelConnection: state.ticketCancelConnection,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        cancelTicket: (ticket_id) => {
            dispatch(Actions.http.connection.tickets.cancel(ticket_id))
        },
        getTicketList: () => {
            const query = {
                type: "view"
            };
            dispatch(Actions.http.connection.tickets.get(query))
        },
        resetTickets: () => {
            dispatch(Actions.data.tickets([]));
        },
        success:  (value) => {
            dispatch(Actions.data.success({
                message: value
            }))
        }
    }
};

class Use extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tickets: [],
            loading: true,
            open: false,
            printOpen: false,
            selectTicket: undefined
        };
        props.getTicketList();
    }


    static getDerivedStateFromProps(nextProps, state) {
        let value = {};
        if (nextProps.tickets.payload !== state.tickets) {
            if (nextProps.ticketConnection.meta.status === Actions.statusEnum.SUCCESS) {
                value.tickets = nextProps.tickets.payload;
            }
        }
        value.loading = nextProps.ticketConnection.meta.fetch || nextProps.ticketCancelConnection.meta.fetch;
        if (0 === Object.keys(value).length) {
            return null;
        }
        return value;
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.ticketCancelConnection !== this.props.ticketCancelConnection) {
            log.debug(this.props.ticketCancelConnection);
            if (this.props.ticketCancelConnection.meta.status === Actions.statusEnum.SUCCESS) {
                this.props.success(this.props.translate("cancel_confirmed"));
                this.props.getTicketList();
            }
        }
    }

    componentWillUnmount(){
        this.props.resetTickets()
    }

    cancel = () => {
        this.setState({
            loading: true,
            open: false,
        }, () => {
            this.props.cancelTicket(this.state.selectTicket.ticket_id)
        })
    };

    confirm = (selectTicket) => {
        this.setState({
            selectTicket,
            open: true
        })
    };

    confirmRePrint = (selectTicket) => {
        this.setState({
            selectTicket,
            printOpen: true
        })
    };

    handleClose = () => {
        this.setState({
            open: false,
            printOpen: false,
            selectTicket: undefined
        })
    }


    print = () => {
        window.printObject = this.state.selectTicket.print_data;
        document.location.href = 'ticketprint://ticketprint';
        this.setState({
            printOpen: false
        })

    }


    render() {
        const {translate, openings,activeLanguage} = this.props;
        let startDayLabel = "";
        let endDayLabel = "";
        let startTimeLabel = "";
        let endTimeLabel = "";
        const value = this.state.selectTicket;
        if(value) {
            if(Helper.isJapanese(activeLanguage.code)){
                startDayLabel = moment(value.start_time.time_real_start).format("YYYY年MM月DD日(dd)");
                endDayLabel = moment(value.end_time.time_real_end).format("YYYY年MM月DD日(dd)");
            }else{
                startDayLabel = moment(value.start_time.time_real_start).format("YYYY/MM/DD");
                endDayLabel = moment(value.end_time.time_real_end).format("YYYY/MM/DD");
            }
            startTimeLabel = moment(value.start_time.time_real_start).format("HH:mm");
            endTimeLabel = moment(value.end_time.time_real_end).format("HH:mm");
        }
        return (
            <div className="App">
                <Header
                    title={translate("menu_reserve_check_cancel")}
                    showMenuButton={true}
                    showLanguageChangeButton={false}
                />
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    style={{
                        wrapper:{
                            overflow: this.props.active ? 'hidden' : 'scroll'
                        }
                    }}
                >
                <div className={"Container"}>
                    {this.state.tickets.length !== 0 ?
                        <div className={"ticket-list"}>
                            {this.state.tickets.map((value, index) => {
                                return <Ticket type={"cancel"}
                                               key={index}
                                               value={value}
                                               callback={this.confirm}
                                               print={this.confirmRePrint}
                                               target={this.props.target.payload}/>
                            })}
                        </div>
                        :
                        this.state.loading ?
                            <span/>
                            :
                            <NoContent
                                actionButtonLabel={translate("back_to_menu")}
                            />
                    }
                </div>
                </LoadingOverlay>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{translate("cancel_reserve_content")}</DialogTitle>
                    {value ?
                        <DialogContent>
                            <FormControl component="fieldset" fullWidth={true}>
                                <FormLabel>{translate("item")}</FormLabel>
                                <span>{Helper.isJapanese(activeLanguage.code) ? value.item.item_name : value.item.item_name_en}</span>
                                <Typography variant="caption" gutterBottom>
                                    {Helper.isJapanese(activeLanguage.code) ? value.item_description : value.item_description_en}
                                </Typography>
                            </FormControl>

                            <FormControl component="fieldset" fullWidth={true}>
                                <FormLabel>{translate("reserve_count")}</FormLabel>
                                <span>{value.ticket_resource_count} {Helper.isJapanese(activeLanguage.code) ? value.item_resource_unit : value.item_resource_unit_en}</span>
                            </FormControl>

                            <FormControl component="fieldset" fullWidth={true}>
                                <FormLabel>{translate("reserve_start")}</FormLabel>
                                <span>{startDayLabel} {startTimeLabel}</span>
                            </FormControl>

                            <FormControl component="fieldset" fullWidth={true}>
                                <FormLabel>{translate("reserve_end")}</FormLabel>
                                <span>{startDayLabel} {endTimeLabel}</span>
                            </FormControl>
                        </DialogContent>
                        :
                        <DialogContent/>
                    }
                    <DialogActions>
                        <Button onClick={this.handleClose} style={{width: 150}} >
                            {translate("disagree")}
                        </Button>
                        <Button onClick={this.cancel} style={{width: 150}} variant="contained"  color={"secondary"} autoFocus>
                            {translate("agree")}
                        </Button>
                    </DialogActions>
                </Dialog>



                <Dialog
                    open={this.state.printOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{translate("re_print_reserve_content")}</DialogTitle>
                    {value ?
                        <DialogContent>
                            <FormControl component="fieldset" fullWidth={true}>
                                <FormLabel>{translate("item")}</FormLabel>
                                <span>{Helper.isJapanese(activeLanguage.code) ? value.item.item_name : value.item.item_name_en}</span>
                                <Typography variant="caption" gutterBottom>
                                    {Helper.isJapanese(activeLanguage.code) ? value.item_description : value.item_description_en}
                                </Typography>
                            </FormControl>

                            <FormControl component="fieldset" fullWidth={true}>
                                <FormLabel>{translate("reserve_count")}</FormLabel>
                                <span>{value.ticket_resource_count} {Helper.isJapanese(activeLanguage.code) ? value.item_resource_unit : value.item_resource_unit_en}</span>
                            </FormControl>

                            <FormControl component="fieldset" fullWidth={true}>
                                <FormLabel>{translate("reserve_start")}</FormLabel>
                                <span>{startDayLabel} {startTimeLabel}</span>
                            </FormControl>

                            <FormControl component="fieldset" fullWidth={true}>
                                <FormLabel>{translate("reserve_end")}</FormLabel>
                                <span>{startDayLabel} {endTimeLabel}</span>
                            </FormControl>

                        </DialogContent>
                        :
                        <DialogContent/>
                    }
                    <DialogActions>
                        <Button onClick={this.handleClose} style={{width: 150}} >
                            {translate("disagree")}
                        </Button>
                        <Button onClick={this.print} style={{width: 150}} variant="contained"  color={"primary"} autoFocus>
                            {translate("agree")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLocalize(Use)));