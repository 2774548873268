export const API_SUB_DIRECTORY = process.env.REACT_APP_API_SUB_DIRECTORY;
export const API_VERSION = process.env.REACT_APP_API_VERSION;

export const API_MAP_STATUS = `/${API_SUB_DIRECTORY}/${API_VERSION}/mapstatuses`;
//認証
export const API_AUTH = `/${API_SUB_DIRECTORY}/${API_VERSION}/authentications`;
export const API_AUTH_CURRENT = `/${API_SUB_DIRECTORY}/${API_VERSION}/authentications/current`;
export const API_BACKDOOR = `/${API_SUB_DIRECTORY}/${API_VERSION}/create_fake_checkin_session`;

//バンドル
export const API_BUNDLES = `/${API_SUB_DIRECTORY}/${API_VERSION}/bundles`;

//リージョン
export const API_REGIONS = `/${API_SUB_DIRECTORY}/${API_VERSION}/regions`;

//空き予約検索 フォームに出すメッセージ
export const API_OPENINGS_SEARCH_FORM_MESSAGE = `/${API_SUB_DIRECTORY}/${API_VERSION}/openings/search-form-message`;
//空き予約検索 検索結果+グラフ
export const API_OPENINGS_GRAPH = `/${API_SUB_DIRECTORY}/${API_VERSION}/openings/graph`;
//空き予約検索 タイムプリセット
export const API_OPENINGS_TIME_PRESET = `/${API_SUB_DIRECTORY}/${API_VERSION}/openings/time-presets`;

//予約
export const API_TICKETS = `/${API_SUB_DIRECTORY}/${API_VERSION}/tickets`;
//スケジュール
export const API_SCHEDULES = `/${API_SUB_DIRECTORY}/${API_VERSION}/schedules`;
//ユーザ
export const API_USERS = `/${API_SUB_DIRECTORY}/${API_VERSION}/users`;
//ユーザタイプ
export const API_USER_TYPE = `/${API_SUB_DIRECTORY}/${API_VERSION}/usertypes`;


//license
export const API_LICENSE = process.env.PUBLIC_URL + '/LICENSE.txt';
