import React, {Component} from 'react';
import {withLocalize, Translate} from 'react-localize-redux';
import PropTypes from 'prop-types';
import {Button, Icon, Paper} from '@material-ui/core'
import log from 'utils/logger';
import Header from "../header/Header";

class NotFound extends Component {
    render() {
        return (
                <div className={"explain"}>
                    <Paper className={"paper"} elevation={1}>
                        <div className={"explain-wrapper"}>
                            <div className="explain-box-left">
                                <Icon className="explain-icon">error</Icon>
                            </div>
                            <div className="explain_box-right">
                                <h3>404 NOT FOUND</h3>
                                <p>該当するページが見つかりません</p>
                            </div>
                        </div>
                        <div className={"explain-action"}>
                            <Button
                                variant={"contained"}
                                color={"secondary"}
                                onClick={() => {
                                    window.location.href = ("/")
                                }}>
                                {this.props.translate("back_to_menu")}
                            </Button>
                        </div>
                    </Paper>
                </div>
        )
    }
}


NotFound.propTypes = {
    title: PropTypes.string,
    headerAction: PropTypes.object,
    showLanguageChangeButton: PropTypes.bool
};
NotFound.defaultProps = {
    title: "",
    headerAction: null,
    showLanguageChangeButton: true
};


export default withLocalize(NotFound);