import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import Button from '@material-ui/core/Button';
import log from 'utils/logger';

const styles = theme => ({
    root: {
    },
    wrapper: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    }
});

class LoadingButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {loading, success} = this.props;
        const {classes} = this.props;
        const buttonClassName = classNames({
            [classes.buttonSuccess]: success,
        });
        return (
            <div className={`loading-button-root ${this.props.className}`}>
                <div className={"loading-button-wrapper"}>
                    <Button
                        fullWidth={this.props.fullWidth}
                        variant={this.props.variant}
                        color={this.props.color}
                        type={this.props.type}
                        size={this.props.size}
                        className={buttonClassName}
                        disabled={this.props.disabled || loading}
                        onClick={this.props.onClick}>
                        {this.props.children}
                    </Button>
                    {loading && <CircularProgress size={24} className={"loading-button-progress"}/>}
                </div>
            </div>
        );
    }
}

LoadingButton.defaultProps={
    type: "default",
    size: "medium",
    color: "default",
    variant: "text",
    loading: false,
    onClick: ()=>{},
    fullWidth: false,
    disabled: false,
    className: ""
};

LoadingButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoadingButton);