import React, {Component} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import {withLocalize, Translate} from 'react-localize-redux';
import {Redirect, withRouter} from "react-router-dom";
import {Icon, Paper} from "@material-ui/core";
import Header from "containers/header/Header";
import LoadingOverlay from "react-loading-overlay";
import connect from "react-redux/es/connect/connect";
import * as Actions from "../../redux/actions";
import IntervalTimer from "react-interval-timer";


const mapStateToProps = (state, props) => {
    return {
        settings: state.settings,
        target: state.target
    }
};
const mapDispatchToProps = dispatch => {
    return {
        doLogout() {
            dispatch(Actions.http.connection.authentication.logout());
        }
    }
};

class CheckOutComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticketObject: {}
        };
    }

    static getDerivedStateFromProps(nextProps, state) {
        let value = {};
        if(0 === Object.keys(value).length){
            return null;
        }
        return value;
    }

    componentDidUpdate(nextProps,prevState){
    }

    render() {
        const {translate, openings} = this.props;
        return (
            <div className="App">
                <Header
                    showMenuButton={true}
                    showLanguageChangeButton={false}
                />
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    style={{
                        wrapper:{
                            overflow: this.props.active ? 'hidden' : 'scroll'
                        }
                    }}>
                    <div className={"Container"}>
                        <div className={"checkin-complete-wrapper"}>
                            <div className="navigation-message-big">
                            <span>
                                <Icon style={{fontSize: 100, margin: 10}}>
                                    check_circle
                                </Icon>
                                <br/>
                                <strong>{translate("check_out_message_1")}</strong>
                                <br/>
                            </span>
                            </div>
                        </div>
                    </div>
                </LoadingOverlay>

                <IntervalTimer
                    timeout={this.props.settings.payload.timeout_time_checkout_complete ? this.props.settings.payload.timeout_time_checkout_complete * 1000 : 60000}
                    callback={this.props.doLogout}
                    enabled={this.props.target.payload === "checkin"}
                    repeat={false}
                />
            </div>
        );
    }
}


export default withRouter(withLocalize(connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckOutComplete)));