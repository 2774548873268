import React, {Component} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import {withLocalize, Translate} from 'react-localize-redux';
import {Redirect, withRouter} from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {Icon, Paper} from "@material-ui/core";
import {SelectValidator, TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import log from "../../utils/logger";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import IconButton from "@material-ui/core/IconButton/IconButton";
import LoadingButton from "../../utils/LoadingButton";
import connect from "react-redux/es/connect/connect";
import moment from "moment/min/moment-with-locales";
import DatePickerDialog from "../../utils/DatePickerDialog";
import * as PropTypes from "prop-types";
import * as Actions from "../../redux/actions";
import Table from "@material-ui/core/Table/Table";
import TableBody from "@material-ui/core/TableBody/TableBody";
import TableRow from "@material-ui/core/TableRow/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import Timeline from 'react-visjs-timeline';
import Button from "@material-ui/core/Button/Button";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import NoContent from "../nocontent/NoContent";
import * as Helper from "../../utils/Helper";


const mapStateToProps = (state, props) => {
    return {
        settings: state.settings,
        regions: state.regions,
        openings: state.openings
    }
};
const mapDispatchToProps = dispatch => {
    return {}
};

class ReserveList extends Component {
    constructor(props) {
        super(props);
        let min;
        if(moment(props.searchData.date_start).isSameOrBefore(moment())){
            min = moment()
        }else{
            min = moment(props.searchData.date_start);
        }
        this.state = {
            loading: false,
            searchData: props.searchData,
            start: moment(props.searchData.date_start).startOf("day"),
            end: moment(props.searchData.date_end).endOf("day"),
            min: min,
            max: moment(props.searchData.date_end).endOf("day"),
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            loading: nextProps.openingConnectionGraph.meta.fetch,
        });
        if (nextProps.openings !== this.props.openings) {
            this.setState({
                openings: nextProps.openings.payload,
            })
        }
    }

    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };

    //一つ変更した際に他も追随
    rangeChangeHandler = (props) => {
        if (
            this.state.selectP !== props
        ) {
            //todo ピコピコしないように同期させるとかが可能であればやりたいですよね
            /*
            this.setState({
                start: props.start,
                end: props.end
            })
            */
        }
    };

    handleSelectPre = (value) => {
        //ここで必要な場合は、警告文を出す
        log.debug(value);
        if(this.props.activeLanguage.code === "jp"){
            if(value.item.item_announcement !== ""){
                this.setState({
                    open: true,
                    selectData: value
                });
                return null;
            }
        }else{
            if(value.item.item_announcement_en !== ""){
                this.setState({
                    open: true,
                    selectData: value
                });
                return null;
            }
        }
        this.handleSelect(value);
    }



    handleSelect = (value) => {
        this.setState({
            open: false,
            selectData: value
        });
        log.debug(value);
        this.props.saveSelectData(value);
        this.props.handleNext();
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const {translate, openings, activeLanguage} = this.props;

        let initialArea = undefined;
        let selectAreas = [];
        if (this.state.region_id) {
            this.props.regions.payload.forEach((value, index) => {
                if (value.region_id === this.state.region_id) {
                    selectAreas = value.areas;
                }
            });
            selectAreas = selectAreas.filter((value, index) => {
                if (value.area_all_flag === true) {
                    initialArea = value.area_id;
                }
                if (this.state.item_type === "1") {
                    return value.seats_flag;
                } else if (this.state.item_type === "2") {
                    return value.equipments_flag;
                } else {
                    return true;
                }
            });
        }
        return (
            <div className={"reserve-list"}>
                <ul>
                    <li>
                        {openings.payload.length !== 0
                            ?
                            openings.payload.map((record, index) => {
                            const options = {
                                locale: activeLanguage.code,
                                format: {
                                    minorLabels: {
                                        millisecond: 'SSS',
                                        second: 's',
                                        minute: 'HH:mm',
                                        hour: 'HH:mm',
                                        weekday: 'M/D(dd)',
                                        day: 'M/D(dd)',
                                        week: 'w',
                                        month: 'MMM',
                                        year: 'YYYY'
                                    },
                                    majorLabels: {
                                        millisecond: 'HH:mm:ss',
                                        second: 'M/D HH:mm',
                                        minute: 'M/D(dd)',
                                        hour: 'M/D(dd)',
                                        weekday: 'YYYY年',
                                        day: 'YYYY年M月',
                                        week: 'YYYY年M月',
                                        month: 'YYYY年',
                                        year: ''
                                    }
                                },
                                editable: false,
                                selectable: false,
                                width: '100%',
                                orientation: "top",
                                height: '130px',
                                stack: false,
                                showMajorLabels: true,
                                showCurrentTime: true,
                                start: this.state.start,
                                end: this.state.end,
                                min: this.state.min,               // lower limit of visible range
                                max: this.state.max,          // upper limit of visible range
                                zoomMin: 1000000,
                            };
                            let unit_label = record.item.item_resource_unit_en;
                            if(Helper.isJapanese(activeLanguage.code)){
                                unit_label = record.item.item_resource_unit;
                            }
                            const editedItems = record.opening_graph.map((value, index) => {
                                let copyObject = Object.assign({}, value);
                                copyObject.start = moment(value.start).format();
                                copyObject.end = moment(value.end).format();
                                log.debug(value);
                                if(copyObject.hasOwnProperty("type")){
                                    copyObject.content = value.content + unit_label;
                                }
                                if (copyObject.count && copyObject.count !== 0 ) {
                                    copyObject.className = "positive"
                                } else {
                                    copyObject.className = "negative"
                                }
                                return copyObject;
                            });

                            return (
                                <Card className={"reserve-card"} key={index}>
                                    <CardHeader
                                        className={"reserve-card-header"}
                                        title={record.item.item_name}
                                        subheader={record.item.item_description}
                                    />
                                    <CardContent className={"reserve-card-content"}>
                                        <Timeline
                                            options={options}
                                            items={editedItems}
                                            rangechangedHandler={this.rangeChangeHandler}
                                        />
                                        <div className={"inner"}>
                                            <Typography color={"secondary"} variant="caption" gutterBottom>
                                                {record.opening_button_announcement.split('\n').map((item, key) => {
                                                    return <span key={key}>{item}<br/></span>
                                                })}
                                            </Typography>
                                        </div>
                                    </CardContent>
                                    <CardActions disableActionSpacing className={"reserve-card-action"}>
                                        {/*
                                        //todo 「SHOW MORE」の
                                        <IconButton
                                            onClick={this.handleExpandClick}
                                            aria-expanded={this.state.expanded}
                                            aria-label="Show more">
                                            <ExpandMoreIcon/>
                                        </IconButton>
                                        */}
                                        <Button className={"detail-button"} color={"primary"} style={{width: 200}}
                                                variant={"contained"} onClick={() => this.handleSelectPre(record)}>{translate("select")}</Button>
                                    </CardActions>
                                    <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                                        <CardContent>

                                        </CardContent>
                                    </Collapse>　
                                </Card>
                            )
                        })
                        :
                            this.state.loading ?
                                <span />
                                :
                                <NoContent
                                    title={"予約できるアイテムが見つかりませんでした。検索条件を変更して再度検索してください"}
                                    withStepper={true}
                                    actionButtonLabel={this.props.translate("back_to_search")}
                                    action={this.props.handleBack}
                                />
                        }
                    </li>
                </ul>
                <Dialog
                    fullWidth={true}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="form-dialog-title">{translate("caution")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {this.state.selectData ?
                                this.props.activeLanguage.code === "jp" ?
                                    this.state.selectData.item.item_announcement
                                    :
                                    this.state.selectData.item.item_announcement_en
                                :
                                null
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={()=> {this.handleSelect(this.state.selectData)}} color="primary">
                            {translate("alert_agree")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


ReserveList.propTypes = {
    handleBack: PropTypes.func,
    handleNext: PropTypes.func
};

ReserveList.defaultProps = {
    handleBack: () => {

    },
    handleNext: () => {

    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLocalize(ReserveList)));