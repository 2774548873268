import { createMuiTheme} from '@material-ui/core';
import lightBlue from "@material-ui/core/colors/lightBlue";


export const getCustomTheme = () => {
    let theme = createMuiTheme({
        palette: {
            primary: {
                main: "#4A90E2"
            },
            secondary: {
                main: "#D0021B"
            }
        },
        typography: {
            useNextVariants: true,
        },
        'breakpoints': {
            'keys': [
                'xs',
                'sm',
                'md',
                'lg',
                'xl',
            ],
            'values': {
                'xs': 360,//スマホ
                'sm' :768,//タブレット
                'md': 992,//PC
                'large' : 1024,
                'xlarge': 1280,
            },
        }
    });

    theme.overrides = {
        MuiFormLabel: {
            root:{
                //FormLabelのoutlineを日英切り替えでshrinkが動かないバグへの暫定対応
                backgroundColor: "white",
                paddingRight: 4,
                paddingLeft: 4
            },
        },
        MuiFormControl : {
            root: {
                marginBottom: 24
            }
        },
        MuiList: {
            root: {
                '& > li:not(:last-child)': {
                    borderBottom: "solid 1px #cccccc"

                }
            }
        },
        MuiRadio: {
            root: {
                height: 24
            }
        },
        MuiInputBase: {
            root:{
                backgroundColor: theme.palette.background.paper,
            }
        },
        MuiInputLabel: {
            shrink: true,
        },
        MuiButtonBase: {
            root: {
                height: 54
            },
        },
        MuiButton:{
          label: {
              //fontSizeを多いとこだけ
              [theme.breakpoints.up('md')]: {
                  fontSize: 16,
                  fontWeight: "bold"
              }
          }
        },
        MuiPickersToolbar: {
            toolbar: {},
        },
        MuiPickersCalendarHeader: {
            transitionContainer: {
                [theme.breakpoints.up('md')]: {
                    height: 72,
                    '& > *': { // label
                        fontSize: 28,
                        lineHeight: "48px",
                        margin: 12,
                        height: 48
                    },
                }
            },
            iconButton: {
                [theme.breakpoints.up('md')]: {
                    '& > *': { // label
                        '& > *': { // icon
                            zIndex: 1,
                            overflow: 'visible',
                            fontSize: 24
                        },
                    },
                }
            },
            switchHeader: {

                [theme.breakpoints.up('md')]: {
                    // backgroundColor: lightBlue.A200,
                    // color: 'white',
                    height: 72,
                    iconButton: {
                        fontSize: 36
                    },
                }
            },
            dayLabel: {
                [theme.breakpoints.up('md')]: {
                    width: 68
                }
            }
        },
        MuiPickersCalendar: {
            transitionContainer: {
                [theme.breakpoints.up('md')]: {
                    minHeight : 408
                }
            }
        },
        MuiPickersDay: {
                day: {
                    [theme.breakpoints.up('md')]: {
                        color: lightBlue.A700,
                        width: 68,
                        height: 68,
                        fontSize: "1.0rem"
                    }
                },
                selected: {

                },
                current: {

                },
        },
        MuiPickersModal: {
            dialogRoot: {

                [theme.breakpoints.up('md')]: {
                    maxWidth: 800
                }
            },
            dialogAction: {},
            dialog: {
                [theme.breakpoints.up('md')]: {
                    width: 800,
                    height: 600
                }
            }
        },
    }
    return theme;
}
