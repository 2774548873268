import React from 'react'
import {connect, store} from 'react-redux'
import {withRouter} from "react-router-dom";
import moment from 'moment';
import 'moment/locale/ja';
import {Link} from "react-router-dom";
import * as GlobalConfig from "../../../constants/GlobalConfig";
import Header from "../../header/Header";
import {getTranslate} from "react-localize-redux/lib/index";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import {withLocalize} from "react-localize-redux/es";
import LoadingOverlay from "react-loading-overlay";


moment.locale('ja');


const mapStateToProps = (state, props) => {
    return {
        mapStatus: state.mapStatus,
        mapLabels: state.mapLabels,
        timePresetSets: state.timePresetSets,
        timePresetSetsConnection: state.timePresetSetsConnection,
        mapStatusConnection: state.mapStatusConnection
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

class Map extends React.Component {
    constructor(props) {
        super(props);
        let calcWidth = window.innerWidth;
        let calcHeight = window.innerHeight - 100;

        let title = (
            <h1>帝京 ACT Three 現在の利用状況</h1>
        );

        this.state = {
            height: calcHeight,
            width: calcWidth,
            currentFloor: 0,
            mapStatus: props.mapStatus.payload,
            mapLabels: props.mapLabels.payload,
            title,
            loading: true,

            visible: false,
        }

    }


    render() {

        const {translate, currentLanguage} = this.props;


        return (
            <div>
                <Header
                    title={GlobalConfig.LoginTitle}
                />
                <div className={"Container"}>
                    <Card>

                        <div className={"flex"}>
                            <div className={"menu-button"}>
                                <Link to={`/map?region=1`}>
                                    <Button type="primary"
                                            className="full-button"
                                            style={{height: "60px", marginTop: "10px"}}>
                                        【ACT3】PCステーション3
                                    </Button>
                                </Link>
                            </div>

                            <div className={"menu-button"}>
                                <Link to={`/map?region=2`}>
                                    <Button type="primary"
                                            className="full-button"
                                            style={{height: "60px", marginTop: "10px"}}>
                                        【ACT1（MELIC）】PCステーション1
                                    </Button>
                                </Link>
                            </div>

                            <div className={"menu-button"}>
                                <Link to={`/map?region=3`}>
                                    <Button type="primary"
                                            className="full-button"
                                            style={{height: "60px", marginTop: "10px"}}>
                                        【ACT2】PCステーション2
                                    </Button>
                                </Link>
                            </div>

                            <div className={"menu-button"}>
                                <Link to={`/map?region=4`}>
                                    <Button type="primary"
                                            className="full-button"
                                            style={{height: "60px", marginTop: "10px"}}>
                                        【ACT2】情報学習室2
                                    </Button>
                                </Link>
                            </div>

                            <div className={"menu-button"}>
                                <Link to={`/map?region=5`}>
                                    <Button type="primary"
                                            className="full-button"
                                            style={{height: "60px", marginTop: "10px"}}>
                                        【ACT2】情報学習室3
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}


Map.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(withRouter(Map)))