import React, {Component} from 'react';
import {withLocalize, Translate} from 'react-localize-redux';
import PropTypes from 'prop-types';
import {Button, Icon, Paper} from '@material-ui/core'
import log from 'utils/logger';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import BasePicker from "material-ui-pickers/_shared/BasePicker";
import Calendar from "material-ui-pickers/DatePicker/components/Calendar";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";

import moment from 'moment';
import TextField from "@material-ui/core/TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import LoadingButton from "./LoadingButton";
import {TextValidator} from "react-material-ui-form-validator";

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class DatePickerDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    handleAction = () => {
        this.props.handleAction();
    }

    handleClose = () => {
        this.props.handleClose();
    };


    render() {
        return (
            <div>
                <Dialog
                    fullScreen
                    open={this.props.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={"relative"}>
                        <Toolbar>
                            <div id="header-logo">
                            </div>
                            <div id={"header-title"}>
                                <Typography variant="h6" color="inherit">
                                    {this.props.title}
                                </Typography>
                            </div>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                <CloseIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <div className={"date-picker-dialog-container"}>
                        <BasePicker value={this.props.selectedDate}
                                    onChange={this.handleDateChange}>
                            {
                                ({
                                     date,
                                     handleAccept,
                                     handleChange,
                                     handleClear,
                                     handleDismiss,
                                     handleSetTodayDate,
                                     handleTextFieldChange,
                                     pick12hOr24hFormat,
                                 }) => {
                                    return (
                                        <div className={"date-picker-dialog"}>
                                            <Calendar date={date}
                                                      onChange={handleChange}
                                                      disablePast={this.props.disablePast}
                                                      shouldDisableDate={this.props.disabledDate}/>
                                                <TextField
                                                    readOnly
                                                    fullWidth
                                                    className={"date-picker-dialog-input"}
                                                    value={date.format("YYYY-MM-DD")}
                                                    id="outlined-disabled"
                                                    margin="normal"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    helperText={this.props.translate("choice_date_placeholder")}
                                                    variant="outlined"
                                                />
                                                <Button
                                                    variant={"contained"}
                                                    type={"submit"}
                                                    fullWidth
                                                    color={"primary"}
                                                    onClick={()=>this.props.handleAction(date)}
                                                >
                                                    確定
                                                </Button>

                                        </div>
                                    )
                                }
                            }
                        </BasePicker>
                    </div>
                </Dialog>
            </div>
        )
    }
}


DatePickerDialog.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleAction: PropTypes.func,
    selectedDate: PropTypes.object,
    disabledDate: PropTypes.func,
    disablePast: PropTypes.bool,
    disableFuture: PropTypes.bool
};
DatePickerDialog.defaultProps = {
    title: "　",
    open: false,
    handleClose: () => {
        log.debug("close用のfunctionを渡せ")
    },
    handleAction: () => {
        log.debug("完了用のfunctionを渡せ")
    },
    disabledDate: (value)=> {
        log.debug(value);
    },
    selectedDate: new Date(),
    disableFuture: false,
    disablePast: false
};


export default withLocalize(DatePickerDialog);