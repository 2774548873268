import request from "superagent";
import nocache from "superagent-no-cache";
import * as Actions from './actions'
import log from 'utils/logger';

export const connection = store => next => action => {
    next(action);
    // POSTリクエスト
    if (action.type.match("^CONNECTION") && action.meta.fetch) {
        if (action.payload.method !== null && action.payload.method === "post") {
            request
                .post(action.payload.api)
                .send(action.payload.data)// data string
                .use(nocache) // Prevents caching of *only* this request
                .end((err, res) => {
                    if (!err) {
                        next(Object.assign(action, {
                                payload: {
                                    ...res.body
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.SUCCESS
                                }
                            })
                        );
                    } else {
                        next(Object.assign(action, {
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );
                    }
                });
        }

        // GETリクエスト
        if (action.payload.method !== null && action.payload.method === 'get') {
            let query = {};
            if (action.payload.hasOwnProperty("query")) {
                query = action.payload.query;
            }
            request
                .get(action.payload.api)
                .query(query)  // query string
                .use(nocache) // Prevents caching of *only* this request
                .end((err, res) => {
                    if (!err) {
                        next(Object.assign(action, {
                                payload: {
                                    ...res.body
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.SUCCESS
                                }
                            })
                        );
                    } else {
                        next(Object.assign(action, {
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );
                    }
                });
        }

        // PUTリクエスト
        if (action.payload.method !== null && action.payload.method === "put") {
            request
                .put(action.payload.api)
                .send(action.payload.data)// data string
                .use(nocache) // Prevents caching of *only* this request
                .end((err, res) => {
                    if (!err) {
                        next(Object.assign(action, {
                                payload: {
                                    ...res.body
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.SUCCESS
                                }
                            })
                        );
                    } else {
                        next(Object.assign(action, {
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );
                    }
                });

        }

        // DELETEリクエスト
        if (action.payload.method !== null && action.payload.method === 'delete') {
            request
                .delete(action.payload.api)
                .query(action.payload.query)  // query string
                .use(nocache) // Prevents caching of *only* this request
                .end((err, res) => {
                    if (!err) {
                        next(Object.assign(action, {
                                payload: {
                                    ...res.body
                                },
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.SUCCESS
                                }
                            })
                        );
                    } else {
                        next(Object.assign(action, {
                                meta: {
                                    fetch: false,
                                    status: Actions.statusEnum.ERROR
                                }
                            })
                        );
                    }
                });
        }
    }
};


export const dataStore = store => next => action => {
    if (action.type.match("^CONNECTION/") && !action.meta.fetch) {
        if (action.meta.status === Actions.statusEnum.SUCCESS) {
            //通信自体は成功　is_succeed毎に処理をする必要性あり
            //SUCCESS
            switch (action.type) {
                case Actions.http.connection.bundles().type:
                    //bundle読み込み終了後にcurrent情報の取得
                    store.dispatch(
                        Actions.http.connection.authentication.current()
                    );
                    store.dispatch(
                        Actions.data.regions(action.payload.result.regions)
                    );
                    store.dispatch(
                        Actions.data.settings(action.payload.result.settings)
                    );
                    store.dispatch(
                        Actions.data.tenants(action.payload.result.tenants)
                    );
                    store.dispatch(
                        Actions.data.timeGroups(action.payload.result.time_groups)
                    );
                    break;
                case Actions.http.connection.authentication.current().type:
                    if (action.payload.hasOwnProperty("is_succeed")) {
                        store.dispatch(
                            Actions.data.session(action.payload.is_succeed)
                        );
                        store.dispatch(
                            Actions.data.account(action.payload.result.account)
                        );
                        store.dispatch(
                            Actions.data.target(action.payload.result.target)
                        )
                    } else {
                        store.dispatch(
                            Actions.data.session(false)
                        );
                    }
                    break;
                case Actions.http.connection.authentication.login().type:
                case Actions.http.connection.authentication.backdoor().type:
                    if (action.payload.hasOwnProperty("is_succeed")) {
                        store.dispatch(
                            Actions.data.session(action.payload.is_succeed)
                        );
                        if (action.payload.is_succeed) {
                            store.dispatch(
                                Actions.data.account(action.payload.result.account)
                            )
                            store.dispatch(
                                Actions.data.target(action.payload.result.target)
                            )
                        }
                    } else {
                        store.dispatch(
                            Actions.data.session(false)
                        );
                    }
                    break;


                case Actions.http.connection.openings.graph().type:
                    if (action.payload.hasOwnProperty("is_succeed") && action.payload.is_succeed) {
                        const openings = action.payload.result.hasOwnProperty("openings") ? action.payload.result.openings : [];
                        log.debug(openings);
                        store.dispatch(
                            Actions.data.openings(openings)
                        );
                    } else {
                        store.dispatch(
                            Actions.data.openings([])
                        );
                    }
                    break;



                case Actions.http.connection.tickets.get().type:
                    if (action.payload.hasOwnProperty("is_succeed") && action.payload.is_succeed) {
                        log.debug(action);
                        const tickets =
                            action.payload.result.hasOwnProperty("tickets")
                                ?
                                action.payload.result.tickets : [];
                        log.debug(tickets);
                        store.dispatch(
                            Actions.data.tickets(tickets)
                        );
                    } else {
                        store.dispatch(
                            Actions.data.tickets([])
                        );
                    }
                    break;

                case Actions.http.connection.authentication.logout().type:
                    store.dispatch(
                        Actions.data.session(false)
                    );
                    if(store.getState().target.payload === "defaultTarget" || store.getState().target.payload === "checkin"){
                        document.location.href = "logout://logout";
                    }
                    break;


                case Actions.http.connection.mapStatus.get().type:
                    store.dispatch(
                        Actions.data.mapStatus(action.payload.result.map_statuses)
                    );
                    store.dispatch(
                        Actions.data.mapLabels(action.payload.result.map_labels)
                    );
                    break;
                default:

                    log.debug(action);

                    break;
            }

            //汎用通信処理でis_succeedではない場合はFAILUREStatusで上書き
            if (action.payload.hasOwnProperty("is_succeed") && action.payload.is_succeed) {
                next(action);
            } else {
                next(Object.assign(action, {
                        meta: {
                            fetch: false,
                            status: Actions.statusEnum.FAILURE
                        }
                    })
                );
                switch(action.type){
                    case "CONNECTION/AUTHENTICATION/CURRENT":
                        break;
                    default:
                        if(action.payload.code !== 1400) {
                            if(action.payload.code === 401){
                                store.dispatch(
                                    Actions.data.session(false)
                                )
                            }
                            store.dispatch(
                                Actions.data.failure(action.payload, true)
                            )
                        }
                        break;
                }
            }

        } else if (action.meta.status === Actions.statusEnum.ERROR) {
            //ERROR
            next(action);
        }
    }else{
        next(action)
    }
};

