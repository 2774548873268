import React from 'react'
import ReactDOM from 'react-dom';
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import {withRouter,Link} from "react-router-dom";
import log from 'utils/logger';
import Header from 'containers/header/Header';
import { withLocalize, Translate } from 'react-localize-redux';
import moment from 'moment';
import 'moment/locale/ja';
import * as Actions from "redux/actions";
import Stepper from "@material-ui/core/es/Stepper/Stepper";
import Step from "@material-ui/core/es/Step/Step";
import StepButton from "@material-ui/core/StepButton/StepButton";
import {Icon, Paper} from "@material-ui/core";
import StepLabel from "@material-ui/core/es/StepLabel/StepLabel";
import NotFound from "../../notfound/NotFound";
import DialBox from "../../../utils/DialBox";
import IntervalTimer from 'react-interval-timer';
import LoadingOverlay from "react-loading-overlay";
import LoadingButton from "../../../utils/LoadingButton";
moment.locale('ja');

const mapStateToProps = (state, props) => {
    return {
        login: state.login,
        usersChangePassConnection: state.usersChangePassConnection
    }
}
const mapDispatchToProps = dispatch => {
    return {
        changePasscode: (data) => {
            dispatch(Actions.http.connection.users.changePass(data))
        },
        setSuccessMessage: (message) => {
            dispatch(Actions.data.success({
                message
            },true))
        },
        setFailureMessage: (message) => {
            dispatch(Actions.data.failure({
                message
            },true))
        },
        doLogout() {
            dispatch(Actions.http.connection.authentication.logout());
        }
    }
}

class PasscodeCheckin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            activeStep: 0,
            new_passcode: "",
            confirm_passcode:"",
            usersChangePassConnection: props.usersChangePassConnection
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        let value = {}
        value.loading = nextProps.usersChangePassConnection.meta.fetch;

        if (nextProps.usersChangePassConnection !== state.usersChangePassConnection) {
            value.usersChangePassConnection = nextProps.usersChangePassConnection;
            if (nextProps.usersChangePassConnection.meta.status === Actions.statusEnum.SUCCESS) {
                value.activeStep = state.activeStep + 2;
            }
        }
        if(0 === Object.keys(value).length){
            return null;
        }
        return value;
    }



    viewPasscodeMask = (isConfirm) => {
        let mask;
        let checkPasscode = this.state.new_passcode;
        if(isConfirm){
            checkPasscode = this.state.confirm_passcode;
        }
        switch(checkPasscode.length){
            case 0:
                mask = "○○○○"
                break;
            case 1:
                mask = "●○○○"
                break;
            case 2:
                mask = "●●○○"
                break;
            case 3:
                mask = "●●●○"
                break;
            case 4:
                mask = "●●●●"
                break;
            default:
                mask = "○○○○"
                ;
        }
        return (
            mask
        )
    };


    getStepContent = (stepIndex) => {
        const {translate} = this.props;
        switch (stepIndex) {
            case 0:
                return(
                    <div>
                        <div className="navigation-message">
                            <span>{translate("change_pass_message_2")}</span>
                        </div>
                        <div
                            className="passcode-input-view">
                            <span className={"number"}>
                                {this.viewPasscodeMask(false)}
                            </span>
                        </div>
                        <DialBox
                            handleInput={(value) => {
                                let passcode = this.state.new_passcode + value;
                                if(passcode.length === 4){
                                    this.setState({
                                        new_passcode: this.state.new_passcode + value,
                                        activeStep: stepIndex + 1
                                    })
                                } else{
                                    this.setState({
                                        new_passcode: this.state.new_passcode + value
                                    })
                                }
                            }}
                            handleBackSpace={() => {
                                this.setState({
                                    new_passcode: this.state.new_passcode.slice(0,-1)
                                })
                            }}
                            handleReset={() => {this.setState({new_passcode: ""})}}
                        />
                    </div>

                )
            case 1:
                return(
                    <div>
                        <div className="navigation-message">
                            <span>{translate("change_pass_message_3")}</span>
                        </div>
                        <div
                            className="passcode-input-view">
                            <span className={"number"}>
                                {this.viewPasscodeMask(true)}
                            </span>
                        </div>
                        <DialBox
                            handleInput={(value) => {
                                let passcode = this.state.confirm_passcode + value;
                                if(passcode.length === 4){
                                    let passcode = this.state.confirm_passcode + value;
                                    if(this.state.new_passcode === passcode) {
                                        this.setState({
                                            confirm_passcode: passcode,
                                        }, () => {
                                            this.props.changePasscode({
                                                account_passcode: passcode
                                            })
                                        })
                                    }else{
                                        this.props.setFailureMessage(
                                            `${translate("not_confirm_passcode")}。${translate("change_pass_message_2")}。`
                                        )
                                        this.setState({
                                            new_passcode: "",
                                            confirm_passcode: "",
                                            activeStep: 0
                                        })
                                    }
                                } else{
                                    this.setState({
                                        confirm_passcode: this.state.confirm_passcode + value
                                    })
                                }
                            }}
                            handleBackSpace={() => {
                                this.setState({
                                    confirm_passcode: this.state.confirm_passcode.slice(0,-1)
                                })
                            }}
                            handleReset={() => {this.setState({confirm_passcode: ""})}}
                        />
                    </div>
                )

            case 3:
                return (
                    <div>
                        <IntervalTimer
                            timeout={15000}
                            callback={()=>{
                                this.props.doLogout()
                            }}
                            enabled={true}
                            repeat={false}
                        />
                        <div className="navigation-message">
                            <Icon style={{fontSize: 100, margin: 10}}>
                                check_circle
                            </Icon>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <strong>{translate("change_pass_message_4")}</strong>
                            <br/>
                            <br/>
                            <br/>
                            <br/>

                            <LoadingButton
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    this.props.history.push("/")
                                }}
                            >
                                {translate("back_to_menu")}
                            </LoadingButton>
                        </div>
                    </div>
                );
            default:
                return <NotFound/>;
        }
    };


    render() {
        const {activeStep} = this.state;
        const {translate} = this.props;
        const formItemLayout = {
            colon: false,
            layout: "inline"
        };
        return (
            <div className="App">
                <Header
                    title={translate("change_passcode")}
                />
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    style={{
                        wrapper:{
                            overflow: this.props.active ? 'hidden' : 'scroll'
                        }
                    }}
                >
                    <div className={"Container with-footer with-stepper"}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        <Step key={1}>
                            <StepButton
                                onClick={() => {
                                    this.setState({
                                        activeStep: 0,
                                        new_passcode: ""
                                    })
                                }}
                                icon={
                                    activeStep === 0 ?
                                        <Icon color={"primary"}>
                                            lock
                                        </Icon>
                                        : 1
                                }
                                disabled={activeStep === 3 || activeStep < 1}
                            >
                                {translate("new_passcode")}
                            </StepButton>
                        </Step>
                        <Step key={2}>
                            <StepButton
                                icon={
                                    activeStep > 1 ?
                                        2 :
                                        <Icon color={activeStep >= 1 ? "primary" : "disabled"}>lock</Icon>
                                }
                                onClick={() => {
                                    this.setState({
                                        activeStep: 1,
                                        confirm_passcode: ""
                                    })
                                }}
                                disabled={activeStep === 3|| activeStep < 2}
                            >
                                {translate("confirm_passcode")}
                            </StepButton>
                        </Step>
                        <Step key={3}>
                            <StepLabel icon={<Icon
                                color={activeStep >= 3 ? "primary" : "disabled"}>done_outline</Icon>}>
                                {translate("change_pass_step_4")}
                            </StepLabel>
                        </Step>
                    </Stepper>


                    <div>
                        {this.getStepContent(activeStep)}
                    </div>

                </div>
                </LoadingOverlay>
            </div>
        );
    }
}
PasscodeCheckin.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(withRouter(PasscodeCheckin)));