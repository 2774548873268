import React from "react";

import PropTypes from 'prop-types';
import {withLocalize} from "react-localize-redux"
import classNames from 'classnames';
import Paper from "@material-ui/core/Paper/Paper";
import Button from "@material-ui/core/Button/Button";
import {createMuiTheme, withStyles} from "@material-ui/core";
import MuiThemeProvider from "@material-ui/core/es/styles/MuiThemeProvider";


const theme = createMuiTheme({
    overrides: {
        MuiButton: {
            contained: {
                backgroundColor: "white",
                '&:hover': {
                    backgroundColor: "white",
                    '@media (hover: none)': {
                        backgroundColor: 'white',
                    },
                },
            },
            containedSecondary: {
                backgroundColor: "#CE0820",
                '&:hover': {
                    backgroundColor: "#CE0820",
                    '@media (hover: none)': {
                        backgroundColor: '#CE0820',
                    },
                },
            },
            label: {
                fontSize: 32,
                fontWeight: "bold"
            }
        },
    },
});

class DialPad extends React.Component {
    render() {
        const {translate, classes} = this.props;

        return (
            <div>
                <div className="keypad">
                    <MuiThemeProvider theme={theme}>
                        <div className={"keypad-keys"}>
                            <div className="keypad-key">
                                <Button variant="contained"
                                        className={"key"}
                                        style={{width: 90, height: 90}}
                                        onTouchStart={() => this.props.onInput(1)}>1</Button>
                            </div>
                            <div className="keypad-key">
                                <Button variant="contained"
                                        className={"key"}
                                        style={{width: 90, height: 90}}
                                        onTouchStart={() => this.props.onInput(2)}>2</Button>
                            </div>
                            <div className="keypad-key">
                                <Button variant="contained"
                                        className={"key"}
                                        style={{width: 90, height: 90}}
                                        onTouchStart={() => this.props.onInput(3)}>3</Button>
                            </div>
                            <div className="keypad-key">
                                <Button
                                    className={"key"}
                                    variant="contained"
                                    style={{width: 90, height: 90}}
                                    onTouchStart={() => this.props.onInput(4)}>4</Button>
                            </div>
                            <div className="keypad-key">
                                <Button className={"key"}
                                        variant="contained"
                                        style={{width: 90, height: 90}}
                                        onTouchStart={() => this.props.onInput(5)}>5</Button>
                            </div>
                            <div className="keypad-key">
                                <Button className={"key"}
                                        variant="contained"
                                        style={{width: 90, height: 90}}
                                        onTouchStart={() => this.props.onInput(6)}>6</Button>
                            </div>
                            <div className="keypad-key">
                                <Button className={"key"}
                                        variant="contained"
                                        style={{width: 90, height: 90}}
                                        onTouchStart={() => this.props.onInput(7)}>7</Button>
                            </div>
                            <div className="keypad-key">
                                <Button className={"key"}
                                        variant="contained"
                                        style={{width: 90, height: 90}}
                                        onTouchStart={() => this.props.onInput(8)}>8</Button>
                            </div>
                            <div className="keypad-key">
                                <Button className={"key"}
                                        variant="contained"
                                        style={{width: 90, height: 90}}
                                        onTouchStart={() => this.props.onInput(9)}>9</Button>
                            </div>
                            <div className="keypad-key keypad-key-zero">
                                <Button className={"key"}
                                        variant="contained"
                                        style={{width: "100%", height: 90}}
                                        onTouchStart={() => this.props.onInput(0)}>0</Button>
                            </div>
                        </div>
                    </MuiThemeProvider>
                    <div className="keypad-menu">
                        <div className="keypad-menu-key"
                             onTouchStart={this.props.onBackSpace}>
                            <Button
                                variant="contained"
                                color={"secondary"}
                                style={{width: 90, height: 90}}
                                className={"key"}>
                                <span style={{fontSize: 24}}>{translate("step_delete")}</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const ConnectedDialPad = withLocalize(DialPad);

export default class DialBox extends React.Component {

    render() {
        return (
            <div className="keypad-box-common">
                <ConnectedDialPad
                    onInput={this.props.handleInput}
                    onBackSpace={this.props.handleBackSpace}
                    onReset={this.props.handleReset}
                />
            </div>
        )
    }
};

DialBox.propTypes = {
    handleInput: PropTypes.func.required,
    handleBackSpace: PropTypes.func.required,
    handleReset: PropTypes.func.required
};

DialBox.defaultProps = {
    handleInput: () => {
        console.log("handleInput is required")
    },
    handleReset: () => {
        console.log("handleReset is required")
    },
    handleBackSpace: () => {
        console.log("handleBackspace is required")
    }
};




