import React, {Component} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import {withLocalize, Translate} from 'react-localize-redux';
import {Redirect, withRouter} from "react-router-dom";
import {connect, store} from 'react-redux'
import * as PropTypes from "prop-types";
import Button from "@material-ui/core/Button/Button";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import moment from 'moment';
import 'moment/locale/ja';
import * as Helper from "../../utils/Helper";
import {Icon, Typography} from "@material-ui/core";
import log from "../../utils/logger";
import * as GlobalConfig from "../../constants/GlobalConfig";
import ReserveStatusButtonSet from "../../utils/ReserveStatusButtonSet";
import {Textfit} from 'react-textfit';
import {ValidatorForm} from "react-material-ui-form-validator";


class Ticket extends Component {

    render() {
        const {translate, activeLanguage, history} = this.props;
        const {value} = this.props;
        let startDayLabel = "";
        let endDayLabel = "";
        let startTimeLabel = "";
        let endTimeLabel = "";
        if (value) {
            if (Helper.isJapanese(activeLanguage.code)) {
                startDayLabel = moment(value.start_time.time_real_start).format("YYYY年MM月DD日(dd)");
                endDayLabel = moment(value.end_time.time_real_end).format("YYYY年MM月DD日(dd)");
            } else {
                startDayLabel = moment(value.start_time.time_real_start).format("YYYY/MM/DD");
                endDayLabel = moment(value.end_time.time_real_end).format("YYYY/MM/DD");
            }
            startTimeLabel = moment(value.start_time.time_real_start).format("HH:mm");
            endTimeLabel = moment(value.end_time.time_real_end).format("HH:mm");
        }

        return (
            <Card className={"ticket"}>
                <CardHeader
                    className={"ticket-header"}
                    title={value.item.item_type === "2" ? `${translate("equipment_name")}${translate("reserve")}` : `${translate("place_name")}${translate("reserve")}`}
                    subheader={Helper.isJapanese(activeLanguage.code) ? value.region_name : value.region_name_en}
                />
                <CardContent className={"ticket-content"}>
                    <div className={"item-container"}>
                        <Textfit mode="single" max={36}>
                            {Helper.isJapanese(activeLanguage.code) ? value.item.item_name : value.item.item_name_en}
                        </Textfit>
                    </div>
                    <div className={"item-detail-container"}>
                        <span>{Helper.isJapanese(activeLanguage.code) ? value.item_description : value.item_description_en}</span>
                    </div>
                    <div className={"item-resource-container"}>
                        <span>{value.ticket_resource_count} {Helper.isJapanese(activeLanguage.code) ? value.item_resource_unit : value.item_resource_unit_en}</span>
                    </div>
                    <div className={"time-container"}>
                        <div>
                            <div className={"time-heading"}>
                                <span>
                                    {translate("reserve_start")}
                                </span>
                            </div>
                            <div className={"time-body"}>
                                <div className={"day-label"}>
                                    <Textfit mode="single" max={16}>
                                        {startDayLabel}
                                    </Textfit>
                                </div>
                                <div className={"time-label"}>
                                    {startTimeLabel}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={"time-heading"}>
                                <span>
                                    {translate("reserve_end")}
                                </span>
                            </div>
                            <div className={"time-body"}>
                                <div className={"day-label"}>
                                    <Textfit mode="single" max={16}>
                                        {endDayLabel}
                                    </Textfit>
                                </div>
                                <div className={"time-label"}>
                                    {endTimeLabel}
                                </div>
                            </div>
                        </div>
                    </div>
                    {value.ticket_can_checkin ? null :
                        <div className={"inner"}>
                            <Typography color={"secondary"} variant="caption" gutterBottom>
                                {
                                    Helper.isJapanese(activeLanguage.code) ?
                                        value.checkin_ticket_announcement :
                                        value.checkin_ticket_announcement_en
                                }
                            </Typography>
                        </div>
                    }
                </CardContent>
                <CardActions disableActionSpacing className={"ticket-action"}>
                    {value ?
                        <ReserveStatusButtonSet
                            value={value}
                            callback={this.props.callback}
                            print={this.props.print}
                            target={this.props.target}
                            type={this.props.type}
                        />
                        :
                        null
                    }
                </CardActions>
                <Collapse in={this.props.expanded} timeout="auto" unmountOnExit>
                    <CardContent>

                    </CardContent>
                </Collapse>
            </Card>
        )
    }
}


Ticket.propTypes = {
    value: PropTypes.object.isRequired,
    type: PropTypes.string,
    target: PropTypes.string,
    callback: PropTypes.func.isRequired,
    print: PropTypes.func
};

Ticket.defaultProps = {
    type: "view",
    target: "client",
    callback: () => {
    },
    print: () => {
    }
};


export default withRouter(withLocalize(Ticket));