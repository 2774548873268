import React, {Component} from 'react';
import {withLocalize, Translate} from 'react-localize-redux';
import PropTypes from 'prop-types';
import {Button, Icon, Paper} from '@material-ui/core'
import log from 'utils/logger';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import TextField from "@material-ui/core/TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import DialBox from "./DialBox";
import { Textfit } from 'react-textfit';

function Transition(props) {
    return <Slide direction="up" {...props} />;
}


class NumberPickerDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputNumber : ""
        }
    }
    handleAction = () => {
        this.props.handleAction();
    }

    handleClose = () => {
        this.props.handleClose();
    };


    render() {
        const {translate} = this.props;
        return (
            <div>
                <Dialog
                    fullScreen
                    open={this.props.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar className={"relative"}>
                        <Toolbar>
                            <div id="header-logo">
                            </div>
                            <div id={"header-title"}>
                                <Typography variant="h6" color="inherit">
                                    {this.props.title}
                                </Typography>
                            </div>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="Close">
                                <CloseIcon/>
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <div className={"number-picker-dialog-container"}>

                        <div className={"number-picker-dialog"}>
                            <TextField
                                readOnly
                                fullWidth
                                className={"date-picker-dialog-input"}
                                value={this.state.inputNumber}
                                id="outlined-disabled"
                                margin="normal"
                                style={{fontSize: "32px"}}
                                InputProps={{
                                    readOnly: true,
                                    startAdornment: (
                                        <InputAdornment  position="start">
                                            <Textfit mode={"single"}>{translate("reserve_user_count")}</Textfit>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            人
                                        </InputAdornment>
                                    ),
                                }}
                                helperText={translate("reserve_user_count_placeholder")}
                                variant="outlined"
                            />
                            <DialBox
                                handleInput={(value) => {
                                    log.debug(value)
                                    if(value === 0 && this.state.inputNumber === "") {
                                        //何もしない
                                    }else{
                                        let new_content = this.state.inputNumber + value;
                                        //最大数以上は入力させない
                                        new_content = Number(new_content) > this.props.maxCount ? this.props.maxCount : new_content;
                                        this.setState({inputNumber: String(new_content)})
                                    }

                                }}
                                handleBackSpace={() => {
                                    this.setState({
                                        inputNumber: this.state.inputNumber.slice(0,-1)
                                    })
                                }}
                                handleReset={() => {this.setState({new_passcode: ""})}}
                            />
                            <Button
                                variant={"contained"}
                                type={"submit"}
                                fullWidth
                                color={"primary"}
                                onClick={()=>this.props.handleAction(this.state.inputNumber)}
                            >
                                {this.props.submitTitle}
                            </Button>

                        </div>
                    </div>
                </Dialog>
            </div>
        )
    }
}


NumberPickerDialog.propTypes = {
    title: PropTypes.string,
    submitTitle: PropTypes.string,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    handleAction: PropTypes.func,
    maxCount: PropTypes.number
};
NumberPickerDialog.defaultProps = {
    title: "　",
    submitTitle: "　",
    open: false,
    handleClose: () => {
        log.debug("close用のfunctionを渡せ")
    },
    handleAction: () => {
        log.debug("完了用のfunctionを渡せ")
    },
    maxCount: 999
};


export default withLocalize(NumberPickerDialog);