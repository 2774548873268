import React, {Component} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import {withLocalize, Translate} from 'react-localize-redux';
import {Redirect, withRouter} from "react-router-dom";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {Icon, Paper} from "@material-ui/core";
import log from "utils/logger";
import connect from "react-redux/es/connect/connect";
import * as Actions from "redux/actions";
import Header from "containers/header/Header";
import Ticket from 'containers/ticket/Ticket';
import NoContent from "../nocontent/NoContent";
import LoadingOverlay from "react-loading-overlay";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Textfit} from "react-textfit";
import * as Helper from "utils/Helper";
import moment from "moment";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Typography from "@material-ui/core/Typography";
const mapStateToProps = (state, props) => {
    return {
        session: state.session,
        target: state.target,
        tickets: state.tickets,
        lockerId: state.lockerId,
        ticketConnection: state.ticketConnection,
        ticketCheckOutConnection: state.ticketCheckOutConnection,
    }
};
const mapDispatchToProps = dispatch => {
    return {
        checkOutTicket: (ticket_id) => {
            dispatch(Actions.http.connection.tickets.checkout(ticket_id))
        },
        getTicketList: () => {
            const query = {
                type: "checkout"
            };
            dispatch(Actions.http.connection.tickets.get(query))
        },
        resetTickets: () => {
            dispatch(Actions.data.tickets([]));
        },
        success:  (value) => {
            dispatch(Actions.data.success({
                message: value
            }))
        }
    }
};

class CheckOut extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tickets: [],
            loading: true,
            isOpenNumberPickerDialog: false,
            isComplete: false,
            ticketCheckOutConnection: props.ticketCheckOutConnection,

            open: false,
            selectTicket: undefined
        };
        props.getTicketList();
    }

    static getDerivedStateFromProps(nextProps, state) {
        let value = {};
        if (nextProps.tickets.payload !== state.tickets) {
            value.tickets = nextProps.tickets.payload;
        }
        if (nextProps.ticketCheckOutConnection !== state.ticketCheckOutConnection) {
            value.ticketCheckOutConnection = nextProps.ticketCheckOutConnection;
            if (nextProps.ticketCheckOutConnection.meta.status === Actions.statusEnum.SUCCESS) {
                nextProps.history.push("/checkout-complete");
            }
        }
        value.loading = nextProps.ticketConnection.meta.fetch || nextProps.ticketCheckOutConnection.meta.fetch;
        if (0 === Object.keys(value).length) {
            return null;
        }
        return value;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.ticketCheckOutConnection !== this.props.ticketCheckOutConnection) {
            if (this.props.ticketCheckOutConnection.meta.status === Actions.statusEnum.SUCCESS) {
                this.props.success(this.props.translate("checkout_confirmed"));
                this.props.getTicketList();
            }
        }
    }

    componentWillUnmount(): void {
        this.props.resetTickets();
    }

    confirm = (selectTicket) => {
        this.setState({
            open: true,
            selectTicket
        })
    }

    handleClose = () => {
        this.setState({
            open: false,
            selectTicket: undefined
        })
    };
    checkOut = () => {
        this.setState({
            loading: true,
            open: false,
        }, () => {
            this.props.checkOutTicket(this.state.selectTicket.ticket_id)
        })
    };


    render() {
        const {translate, openings,activeLanguage} = this.props;
        let startDayLabel = "";
        let endDayLabel = "";
        let startTimeLabel = "";
        let endTimeLabel = "";
        const value = this.state.selectTicket;
        if(value) {
            if(Helper.isJapanese(activeLanguage.code)){
                startDayLabel = moment(value.start_time.time_real_start).format("YYYY年MM月DD日(dd)");
                endDayLabel = moment(value.end_time.time_real_end).format("YYYY年MM月DD日(dd)");
            }else{
                startDayLabel = moment(value.start_time.time_real_start).format("YYYY/MM/DD");
                endDayLabel = moment(value.end_time.time_real_end).format("YYYY/MM/DD");
            }
            startTimeLabel = moment(value.start_time.time_real_start).format("HH:mm");
            endTimeLabel = moment(value.end_time.time_real_end).format("HH:mm");
        }
        return (
            <div className="App">
                <Header
                    title={translate("checkout")}
                    showMenuButton={true}
                    showLanguageChangeButton={false}
                />
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    style={{
                        wrapper: {
                            overflow: this.props.active ? 'hidden' : 'scroll'
                        }
                    }}
                >
                    <div className={"Container"}>
                        {this.state.tickets.length === 0 ?
                            this.state.loading ?
                                <span/>
                                :
                                <NoContent
                                    actionButtonLabel={this.props.translate("back_to_menu")}
                                />
                            :
                            <div className={"ticket-list"}>
                                {
                                    this.state.tickets.map((value, index) => {
                                        return <Ticket type={"checkout"}
                                                       key={index}
                                                       value={value}
                                                       callback={this.confirm}
                                                       target={this.props.target.payload}/>
                                    })
                                }
                            </div>
                        }
                    </div>
                </LoadingOverlay>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{translate("checkout_reserve_content")}</DialogTitle>
                    {value ?
                        <DialogContent>
                            <FormControl component="fieldset" fullWidth={true}>
                                <FormLabel>{translate("item")}</FormLabel>
                                <span>{Helper.isJapanese(activeLanguage.code) ? value.item.item_name : value.item.item_name_en}</span>
                                <Typography variant="caption" gutterBottom>
                                    {Helper.isJapanese(activeLanguage.code) ? value.item_description : value.item_description_en}
                                </Typography>
                            </FormControl>

                            <FormControl component="fieldset" fullWidth={true}>
                                <FormLabel>{translate("reserve_count")}</FormLabel>
                                <span>{value.ticket_resource_count} {Helper.isJapanese(activeLanguage.code) ? value.item_resource_unit : value.item_resource_unit_en}</span>
                            </FormControl>

                            <FormControl component="fieldset" fullWidth={true}>
                                <FormLabel>{translate("reserve_start")}</FormLabel>
                                <span>{startDayLabel} {startTimeLabel}</span>
                            </FormControl>

                            <FormControl component="fieldset" fullWidth={true}>
                                <FormLabel>{translate("reserve_end")}</FormLabel>
                                <span>{startDayLabel} {endTimeLabel}</span>
                            </FormControl>
                        </DialogContent>
                        :
                        <DialogContent/>
                    }
                    <DialogActions>
                        <Button onClick={this.handleClose} style={{width: 150}} >
                            {translate("disagree")}
                        </Button>
                        <Button onClick={this.checkOut} style={{width: 150}} variant="contained"  color={"primary"} autoFocus>
                            {translate("agree")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}


export default withRouter(withLocalize(connect(
    mapStateToProps,
    mapDispatchToProps
)(CheckOut)));