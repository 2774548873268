import React, {Component} from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize, Translate } from 'react-localize-redux';
import {Redirect, withRouter} from "react-router-dom";
import Message from "./containers/message/Message";
import {Route, Switch} from "react-router";
import Login from "./containers/login/Login";
import Dev from "./containers/develop/Dev";
import NotFound from "./containers/notfound/NotFound";
import MainMenu from "./containers/menu/MainMenu";
import Reserve from "./containers/reserve/Reserve";
import CancelList from "./containers/reserve/cancel/CancelList";
import PasscodeClient from './containers/passcode/client/PasscodeClient';
import PasscodeCheckin from './containers/passcode/checkin/PasscodeCheckin';
import CheckIn from "./containers/checkin/CheckIn";
import CheckInComplete from "./containers/checkin/CheckInComplete";
import CheckOut from "./containers/checkout/CheckOut";
import CheckOutComplete from "./containers/checkout/CheckOutComplete";
import MapIndex from "./containers/map/teikyo/MapIndex";
import MapLoader from "./containers/map/MapLoader";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate : new Date()
        };
    }

    render() {
        const {translate} = this.props;
        return (
            <Switch>
                <Route path="/" exact
                    render={props =>
                        <MainMenu />
                    }/>
                <Route
                    exact
                    path={"/reserve"}
                    render={props =>
                        <Reserve />
                    }/>
                <Route
                    exact
                    path={"/menu"}
                    render={props =>
                        <MainMenu />
                    }/>
                <Route
                    exact
                    path={"/cancel"}
                    render={props =>
                        <CancelList />
                    }/>
                <Route
                    exact
                    path={"/change-passcode"}
                    render={props =>
                        <PasscodeClient />
                    }                 />
                <Route
                    path={"/change-passcode-checkin"}
                    render={props =>
                        <PasscodeCheckin />
                    }                 />
                <Route
                    exact
                    path={"/checkin"}
                    render={props =>
                        <CheckIn />
                    }
                    />

                <Route
                    exact
                    path={`/mapindex`}
                    render={props =>
                        <MapIndex />
                    }
                />
                <Route
                    exact
                    path={`/map`}
                    render={props =>
                        <MapLoader />
                    }
                />
                <Route
                    exact
                    path={"/checkout"}
                    render={props =>
                        <CheckOut />
                    }
                />
                <Route
                    exact
                    path={"/checkin-complete"}
                    render={props =>
                        <CheckInComplete />
                    }
                />
                <Route
                    exact
                    path={"/checkout-complete"}
                    render={props =>
                        <CheckOutComplete />
                    }
                />
                <Route
                    component={NotFound}
                />

            </Switch>
        );
    }
}

export default withRouter(withLocalize(App));