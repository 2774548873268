import {createActions, createAction, handleActions, combineActions} from 'redux-actions'
import * as url from '../constants/api'
import log from '../utils/logger';

export const statusEnum = {
    REQUEST: "REQUEST",
    SUCCESS: "SUCCESS",
    FAILURE: "FAILURE",
    ERROR: "ERROR"
};


export const http = createActions({
    CONNECTION: {
        AUTHENTICATION: {
            CURRENT: [
                () => ({
                    method: "get",
                    api: url.API_AUTH_CURRENT,
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            LOGIN: [
                (data) => ({
                    method: "post",
                    api: url.API_AUTH,
                    data
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            BACKDOOR: [
                () => ({
                    method: "get",
                    api: url.API_BACKDOOR
                }),
                () => ({fetch: true, status: statusEnum.REQUEST})

            ],
            LOGOUT: [
                () => ({method: "delete", api: url.API_AUTH}),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        },
        USERS: {
            SIGNUP: [
                (data) => ({method: "post", api: url.API_USERS, data}),
                () => ({fetch:true, status: statusEnum.REQUEST})
            ],
            CHANGE_PASS: [
                (data) => ({method: "put", api: url.API_USERS, data}),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        },
        BUNDLES: [
            () => ({method: "get", api: url.API_BUNDLES}),
            () => ({fetch: true, status: statusEnum.REQUEST})
        ],
        REGIONS: [
            () => ({method: "get", api: url.API_REGIONS}),
            () => ({fetch: true, status: statusEnum.REQUEST})
        ],
        OPENINGS: {
            SEARCH_FORM_MESSAGE : [
                (query) => ({method: "get", api: url.API_OPENINGS_SEARCH_FORM_MESSAGE, query}),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            GRAPH: [
                (query) => ({method: "get", api: url.API_OPENINGS_GRAPH, query}),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            TIME_PRESET: [
                (query) => ({method: "get", api: url.API_OPENINGS_TIME_PRESET, query}),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        },
        TICKETS: {
            GET: [
                (query) => ({method: "get", api: url.API_TICKETS, query}),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            POST: [
                (data) => ({method: "post", api: url.API_TICKETS, data}),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            CANCEL: [
                (ticket_id) => ({method: "get", api: `${url.API_TICKETS}/${ticket_id}`, query: {type: "cancel"}}),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            CHECKIN: [
                (ticket_id,ticket_user_count) => ({method: "get", api: `${url.API_TICKETS}/${ticket_id}`, query: {type: "checkin",ticket_user_count: ticket_user_count}}),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
            CHECKOUT: [
                (ticket_id) => ({method: "get", api: `${url.API_TICKETS}/${ticket_id}`, query: {type: "checkout"}}),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ]
        },
        SCHEDULES: [
            () => ({method: "get", api: url.API_SCHEDULES}),
            () => ({fetch: true, status: statusEnum.REQUEST})
        ],
        MAP_STATUS: {
            GET: [
                () => ({method: "get", api: `${url.API_MAP_STATUS}`}),
                () => ({fetch: true, status: statusEnum.REQUEST})
            ],
        }
    }
});


export const data = createActions({
    FAILURE: [
        (data) => data,
        (data,bool) => ({isShow: bool})
    ],
    SUCCESS: [
        (data) => data,
        (data,bool) => ({isShow: bool})
    ],
    ACCOUNT: data => ({...data}),
    TARGET: data => (data),
    BUNDLES: data => (data),
    REGIONS: data => (data),
    SETTINGS: data => ({...data}),
    TENANTS: data => (data),
    SESSION: (is_authenticated) => ({
            is_checked: true,
            is_authenticated
        }
    ),
    TIME_GROUPS: data => (data),
    OPENINGS: data => (data),
    OPENING_TIME_ANNOUNCEMENT: data => data,
    RESERVE_TIME : data => data,
    TIME_PRESET_SETS : data => (data),

    TICKETS: data => (data),

    MAP_STATUS: data => data,
    MAP_LABELS : data => data,
});