import React, {Component} from 'react';
import {withLocalize, Translate} from 'react-localize-redux';
import PropTypes from 'prop-types';
import {Button, Icon, Paper} from '@material-ui/core'
import log from 'utils/logger';
import Header from "../header/Header";
import classNames from "classnames";

class NoContent extends Component {
    render() {
        const explainClass = classNames(
            "explain",
            {
                "explain-with-header":this.props.withHeader,
                "explain-with-stepper": this.props.withStepper
            }
        )
        return (
            <div className={explainClass}>
                <Paper className={"paper"} elevation={1}>
                    <div className={"explain-wrapper"}>
                        <div className="explain-box-left">
                            <Icon className="explain-icon">error</Icon>
                        </div>
                        <div className="explain_box-right">
                            <h3>{this.props.title_en}</h3>
                            <p>{this.props.title}</p>
                        </div>
                    </div>
                    <div className={"explain-action"}>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            onClick={this.props.action}>
                            {this.props.actionButtonLabel}
                        </Button>
                    </div>
                </Paper>
            </div>
        )
    }
}


NoContent.propTypes = {
    title: PropTypes.string,
    title_en: PropTypes.string,
    headerAction: PropTypes.object,
    showLanguageChangeButton: PropTypes.bool,
    withHeader: PropTypes.bool,
    withStepper: PropTypes.bool,
    action: PropTypes.func
};
NoContent.defaultProps = {
    title: "該当するチケットが存在しません",
    title_en: "NO CONTENT",
    headerAction: null,
    showLanguageChangeButton: true,
    withHeader: true,
    withStepper: false,
    actionButtonLabel: "",
    action: ()=>{
        window.location.href = ("/")
    }
};


export default withLocalize(NoContent);