import React from 'react'
import ReactDOM from 'react-dom';
import {connect, store} from 'react-redux'
import {instanceOf} from 'prop-types';
import {withRouter,Link} from "react-router-dom";
import log from 'utils/logger';
import Header from 'containers/header/Header';
import { withLocalize, Translate } from 'react-localize-redux';
import moment from 'moment';
import 'moment/locale/ja';
import * as Actions from "redux/actions";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {Icon, Paper, Typography} from "@material-ui/core";
import LoadingButton from "../../../utils/LoadingButton";
moment.locale('ja');

const mapStateToProps = (state, props) => {
    return {
        login: state.login,
        usersChangePassConnection: state.usersChangePassConnection
    }
}
const mapDispatchToProps = dispatch => {
    return {
        changePasscode: (data) => {
            log.debug(data);
            dispatch(Actions.http.connection.users.changePass(data))
        },
        setSuccessMessage: (message) => {
            dispatch(Actions.data.success({
                message
            },true))
        }
    }
}

class PasscodeClient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            current_passcode :"",
            new_passcode: "",
            confirm_passcode: "",
            usersChangePassConnection: props.usersChangePassConnection
        }
        ValidatorForm.addValidationRule('isPasscodeMatch', (value) => {
            if (value !== this.state.new_passcode) {
                return false;
            }
            return true;
        });
    }

    static getDerivedStateFromProps(nextProps, state) {
        let value = {}
        if (nextProps.usersChangePassConnection !== state.usersChangePassConnection) {
            if (nextProps.usersChangePassConnection.meta.status === Actions.statusEnum.SUCCESS) {
                value.current_passcode = "";
                value.new_passcode = "";
                value.confirm_passcode = "";
            }
            value.usersChangePassConnection = nextProps.usersChangePassConnection
        }
        value.loading = nextProps.usersChangePassConnection.meta.fetch;
        return value;
    }


    componentDidUpdate(prevProps,prevState) {
        if (prevProps.usersChangePassConnection !== this.props.usersChangePassConnection) {
            if (this.props.usersChangePassConnection.meta.status === Actions.statusEnum.SUCCESS) {
                this.props.setSuccessMessage(this.props.translate("success_change_passcode"));
                this.props.history.push("/");
            }
        }
    }



    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };


    submitChangePass = (event) => {
        event.preventDefault();
        const values = {
            account_passcode_new: this.state.new_passcode,
            account_passcode_old: this.state.current_passcode,
        }
        this.props.changePasscode(values);
    };


    render() {
        const {translate} = this.props;
        const formItemLayout = {
            colon: false,
            layout: "inline"
        };
        return (
            <div className="App">
                <Header
                    title={translate("change_passcode")}
                
                />
                <div className={"Container with-footer"}>
                    <div className={"passcode-wrapper"}>
                        <Paper className={"paper"} elevation={1}>
                        <h2>{translate("change_passcode")}</h2>
                        <ValidatorForm
                            onSubmit={this.submitChangePass}>
                            <TextValidator
                                fullWidth
                                id="current_passcode"
                                label={translate("current_passcode")}
                                placeholder={translate("current_passcode_placeholder")}
                                margin="normal"
                                variant="outlined"
                                type="password"
                                name="current_passcode"
                                validators={['required']}
                                errorMessages={[translate("current_passcode_placeholder")]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.current_passcode}
                                onChange={this.handleChange("current_passcode")}
                            />
                            <TextValidator
                                fullWidth
                                id="new_passcode"
                                label={translate("new_passcode")}
                                placeholder={translate("new_passcode_placeholder")}
                                margin="normal"
                                variant="outlined"
                                type="password"
                                name="new_passcode"
                                validators={['required']}
                                errorMessages={[translate("new_passcode_placeholder")]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.new_passcode}
                                onChange={this.handleChange("new_passcode")}
                            />
                            <TextValidator
                                fullWidth
                                id="confirm_passcode"
                                label={translate("confirm_passcode")}
                                placeholder={translate("confirm_passcode_placeholder")}
                                margin="normal"
                                variant="outlined"
                                type="password"
                                name="confirm_passcode"
                                validators={['isPasscodeMatch','required']}
                                errorMessages={[translate("not_confirm_passcode"),translate("confirm_passcode_placeholder")]}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={this.state.confirm_passcode}
                                onChange={this.handleChange("confirm_passcode")}
                            />
                            <LoadingButton
                                fullWidth={true}
                                variant={"contained"}
                                type={"submit"}
                                color={"primary"}
                                loading={this.state.loading}
                            >
                                {translate("change_passcode")}
                            </LoadingButton>
                        </ValidatorForm>
                    </Paper>
                    </div>
                </div>
            </div>
        );
    }
}
PasscodeClient.propTypes = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(withRouter(PasscodeClient)));