import React, {Component} from 'react';
import {withLocalize, Translate} from 'react-localize-redux';
import PropTypes from 'prop-types';
import {Button} from '@material-ui/core'
import connect from "react-redux/es/connect/connect";
import * as Actions from "../../redux/actions";
import LoadingButton from "../../utils/LoadingButton";
import {withRouter} from "react-router";


const mapStateToProps = (state, props) => {
    return {
        logoutConnection: state.logoutConnection
    }
};


const mapDispatchToProps = dispatch => {
    return {
        doLogout() {
            dispatch(Actions.http.connection.authentication.logout());
        }
    }
};

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date(),
            logoutConnection: props.logoutConnection
        };
    }


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.logoutConnection !== this.props.logoutConnection) {
            this.setState({
                logoutConnection: this.props.logoutConnection
            })
        }
    }

    render() {
        const {languages, activeLanguage, setActiveLanguage, translate, history} = this.props;
        if (!activeLanguage) {
            return <header/>;
        }

        return (
            <header>
                <div>
                    <div id="header-logo">
                        <div id={"header-logo-img"}/>
                    </div>
                    <div id={"header-title"}>
                        <span>{this.props.title}</span>
                    </div>
                    <div id="header-action">
                        {this.props.showLanguageChangeButton ?
                            <Button variant="contained"
                                    onClick={() => {
                                        setActiveLanguage(activeLanguage.code === "jp" ? "en" : "jp")
                                    }}>
                                {activeLanguage.code === "jp" ? "EN" : "JP"}
                            </Button>
                            : null
                        }
                        {this.props.showLogoutButton ?
                            <LoadingButton
                                variant="contained"
                                color={"secondary"}
                                loading={this.state.logoutConnection.meta.fetch}
                                onClick={this.props.doLogout}>
                                {translate("logout")}
                            </LoadingButton>
                            : null
                        }
                        {this.props.showMenuButton ?
                            <LoadingButton
                                variant="contained"
                                color={"primary"}
                                onClick={()=>{
                                    history.push("/")
                                }}>
                                {translate("back_to_menu")}
                            </LoadingButton>
                            :
                            null
                        }
                        {this.props.headerAction}
                    </div>
                </div>
            </header>
        );
    }
}


Header.propTypes = {
    title: PropTypes.string,
    headerAction: PropTypes.object,
    showLanguageChangeButton: PropTypes.bool,
    showLogoutButton: PropTypes.bool,
    showMenuButton: PropTypes.bool
};
Header.defaultProps = {
    title: "",
    headerAction: null,
    showLanguageChangeButton: false,
    showLogoutButton: false,
    showMenuButton: true
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLocalize(Header)));