import React from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import {withLocalize} from 'react-localize-redux';
import {Route, Router, Switch} from 'react-router'
import Login from "./containers/login/Login";
import Dev from "./containers/develop/Dev";
import App from "./App";
import * as Actions from "./redux/actions";
import connect from "react-redux/es/connect/connect";
import {withRouter, Redirect} from "react-router-dom";
import log from 'utils/logger';
import Message from "./containers/message/Message";
import Signup from "./containers/signup/Signup";
import LoadingOverlay from 'react-loading-overlay';
import * as Helper from "./utils/Helper";
import LoginDummy from "./containers/login/LoginDummy";

const onMissingTranslation = ({ defaultTranslation }) => defaultTranslation;

const mapStateToProps = (state, props) => {
    return {
        session: state.session,
        authConnection: state.authConnection,
        bundleConnection: state.bundleConnection
    }
}

const mapDispatchToProps = dispatch => {
    return {
        checkLoggedIn: () => {
            dispatch(Actions.http.connection.bundles());
        }
    }
};

class InitializeRouter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            session: props.session,
            loading: true
        };
        if(Helper.getUniversityCodeFromDomain() === Helper.universityCodeEnum.TEIKYO){
            // module.jsをインポートする
            import('translations/teikyo/jp.translations.json').then(
                // defaultとして読み込んだモジュールを「MyModule」という名前空間として、
                // then()のコールバック関数の引数に渡す
                (module) => {
                    this.props.initialize({
                        languages: [
                            {name: "Japanese", code: "jp"},
                            {name: "English", code: "en"}
                        ],
                        translation: module,
                        options: {
                            defaultLanguage: "jp",
                            renderToStaticMarkup,
                            onMissingTranslation,
                            renderInnerHtml: true,
                        },
                    });
                    this.props.setActiveLanguage("jp");
                }
            ).catch(err => {
                //  エラー処理
            });
            // module.jsをインポートする
            import('translations/teikyo/en.translations.json')
                .then((module) => {
                        this.props.addTranslationForLanguage(module, "en");
                    }
                ).catch(err => {
                //  エラー処理
            });
        }else if(Helper.getUniversityCodeFromDomain() === Helper.universityCodeEnum.KANAGAWA){
            // module.jsをインポートする
            import('translations/kanagawa/jp.translations.json').then(
                // defaultとして読み込んだモジュールを「MyModule」という名前空間として、
                // then()のコールバック関数の引数に渡す
                (module) => {
                    this.props.initialize({
                        languages: [
                            {name: "Japanese", code: "jp"},
                            {name: "English", code: "en"}
                        ],
                        translation: module,
                        options: {
                            defaultLanguage: "jp",
                            renderToStaticMarkup,
                            onMissingTranslation,
                            renderInnerHtml: true,
                        },
                    });
                    this.props.setActiveLanguage("jp");
                }
            ).catch(err => {
                //  エラー処理
            });
            // module.jsをインポートする
            import('translations/kanagawa/en.translations.json')
                .then((module) => {
                        this.props.addTranslationForLanguage(module, "en");
                    }
                ).catch(err => {
                //  エラー処理
            });
        }else{
            // module.jsをインポートする
            import('translations/jp.translations.json').then(
                // defaultとして読み込んだモジュールを「MyModule」という名前空間として、
                // then()のコールバック関数の引数に渡す
                (module) => {
                    this.props.initialize({
                        languages: [
                            {name: "Japanese", code: "jp"},
                            {name: "English", code: "en"}
                        ],
                        translation: module,
                        options: {
                            defaultLanguage: "jp",
                            renderToStaticMarkup,
                            onMissingTranslation,
                            renderInnerHtml: true,
                        },
                    });
                    this.props.setActiveLanguage("jp");
                }
            ).catch(err => {
                //  エラー処理
            });
            // module.jsをインポートする
            import('translations/en.translations.json')
                .then((module) => {
                        this.props.addTranslationForLanguage(module, "en");
                    }
                ).catch(err => {
                //  エラー処理
            });
        }

        // this.props.initialize({
        //     languages: [
        //         {name: "Japanese", code: "jp"},
        //         {name: "English", code: "en"}
        //     ],
        //     translation: jp,
        //     options: {
        //         defaultLanguage: "jp",
        //         renderToStaticMarkup,
        //         onMissingTranslation,
        //         renderInnerHtml: true,
        //     },
        // });
        // this.props.setActiveLanguage("jp");
        // this.props.addTranslationForLanguage(en, "en");

        props.checkLoggedIn();
    }


    componentDidUpdate(prevProps, prevState) {
    }

    static getDerivedStateFromProps(nextProps, state) {
        let value ={}
        value.loading = nextProps.authConnection.meta.fetch || nextProps.bundleConnection.meta.fetch;
        value.session = nextProps.session;
        return value;
    }


    render() {
        const {session} = this.state;

        return (
            <div>
                <LoadingOverlay
                    active={this.state.loading}
                    spinner
                    text={this.state.message}
                    style={{
                        wrapper:{
                            overflow: this.props.active ? 'hidden' : 'scroll'
                        }
                    }}
                >
                <Message />
                <Switch>
                    <Route
                        exact
                        path={"/login"}
                        render={props =>
                            session.payload.is_checked && !this.state.loading ?
                                session.payload.is_authenticated ?
                                    <Redirect to={{
                                        pathname: `/`,
                                        state: {from: props.location}
                                    }}/>
                                    :
                                    <Login/>
                                :
                                <span/>
                        }
                    />
                    <Route path={"/signup"}
                           render={props =>
                               session.payload.is_checked && !this.state.loading ?
                                   session.payload.is_authenticated ?
                                       <Redirect to={{
                                           pathname: `/`,
                                           state: {from: props.location}
                                       }}/>
                                       :
                                       <Signup/>
                                   :
                                   <span />
                           }
                           />
                    <Route
                        exact
                        path={"/dev"}
                        render={props =>
                            session.payload.is_checked && !this.state.loading ?
                                session.payload.is_authenticated ?
                                    <Redirect to={{
                                        pathname: `/`,
                                        state: {from: props.location}
                                    }}/>
                                    :
                                    <LoginDummy/>
                                :
                                <span/>
                        }
                    />
                    <Route
                        render={props =>
                            session.payload.is_checked && !this.state.loading ?
                                session.payload.is_authenticated ?
                                    <App/>
                                    :
                                    <Redirect to={{
                                        pathname: `/login`,
                                        state: {from: props.location}
                                    }}/>
                                :
                                <span />
                        }
                    />
                </Switch>
                </LoadingOverlay>
            </div>
        )
    }
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(InitializeRouter)));
