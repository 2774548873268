import React, {Component} from "react"
import {connect} from 'react-redux'
import * as Actions from "redux/actions";
import classNames from 'classnames';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom'
import * as PropTypes from "prop-types";


const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};
const styles1 = theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.dark,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing.unit,
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
});
const MySnackbarContent = (props) => {
    const { classes, className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            className={classNames(classes[variant], className)}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
                    {message}
        </span>
            }
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={onClose}
                >
                    <CloseIcon className={classes.icon} />
                </IconButton>,
            ]}
            {...other}
        />
    );
}
MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};
const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);


const mapStateToProps = (state, props) => {
    return {
        failure: state.failure,
        success: state.success,
    }
};


const mapDispatchToProps = dispatch => {
    return {
        resetError: () => {
            const reset = {
                message: ""
            };
            dispatch(Actions.data.failure(reset, false))
        },
        resetSuccess: () => {
            const reset = {
                message: ""
            };
            dispatch(Actions.data.success(reset, false))
        }
    }
};



class Message extends Component {
    constructor(props) {
        super(props);
        this.state = {
            failure: props.failure,
            success: props.success,
            open: false,
            variant: "default",
            message: ""
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.failure !== this.props.failure){
            this.setState({
                open : true,
                variant: "error",
                message: this.props.failure.payload.message,
                failure: this.props.failure
            });

            //Message側で処理をする
            if(this.props.failure.payload.code === 401){
                this.props.history.push("/login");
            }
        }
        if(prevProps.success !== this.props.success){
            this.setState({
                success: this.props.success
            })
            this.setState({
                open : true,
                variant: "success",
                message: this.props.success.payload.message,
                success: this.props.success
            })
        }
    }

    handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false });
    };

    render() {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={this.state.open}
                autoHideDuration={3000}
                onClose={this.handleClose}
            >
                <MySnackbarContentWrapper
                    onClose={this.handleClose}
                    variant={this.state.variant}
                    message={this.state.message}
                />
            </Snackbar>
        )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Message))


