import {createActions, createAction, handleAction,handleActions, combineActions} from 'redux-actions'
import * as Actions from './actions';
import log from 'utils/logger';

const defaultState = ({
    payload:{
    },
    meta: {
        fetch: false,
        status: ""
    }
});


const defaultReducerMap = {
    next: (state, action) => {
        return {...action}
    },
    throw: (state, action) => {
        return (Object.assign(action, {
            meta: {
                fetch: false,
                status: Actions.statusEnum.FAILURE
            }
        }))
    }
};


export const bundleConnection = handleAction(
    Actions.http.connection.bundles,
    defaultReducerMap,defaultState
)
export const authConnection = handleAction(
    Actions.http.connection.authentication.current,
    defaultReducerMap,defaultState
);
export const loginConnection = handleAction(
    combineActions(
        Actions.http.connection.authentication.login,
        Actions.http.connection.authentication.backdoor,
    ), defaultReducerMap, defaultState);

export const logoutConnection = handleAction(
    Actions.http.connection.authentication.logout,
    defaultReducerMap,
    defaultState
);

export const signupConnection = handleAction(
    Actions.http.connection.users.signup,
    defaultReducerMap,
    defaultState
);
export const usersChangePassConnection = handleAction(
    combineActions(
        Actions.http.connection.users.changePass,
    ), defaultReducerMap, defaultState);

export const ticketConnection = handleAction(
    combineActions(
        Actions.http.connection.tickets.post,
        Actions.http.connection.tickets.get,
    ), defaultReducerMap, defaultState
);
export const ticketCancelConnection = handleAction(
    combineActions(
        Actions.http.connection.tickets.cancel,
    ), defaultReducerMap, defaultState
);
export const ticketCheckInConnection = handleAction(
    combineActions(
        Actions.http.connection.tickets.checkin,
    ), defaultReducerMap, defaultState
);
export const ticketCheckOutConnection = handleAction(
    combineActions(
        Actions.http.connection.tickets.checkout,
    ), defaultReducerMap, defaultState
);

export const openingConnectionGraph = handleAction(
    combineActions(
        Actions.http.connection.openings.graph,
    ), defaultReducerMap, defaultState
);
export const openingConnectionTimePreset = handleAction(
    combineActions(
        Actions.http.connection.openings.timePreset,
    ), defaultReducerMap, defaultState
);
export const openingConnectionSearchFormMessage = handleAction(
    combineActions(
        Actions.http.connection.openings.searchFormMessage,
    ), defaultReducerMap, defaultState
);

export const mapStatusConnection = handleAction(
    combineActions(
        Actions.http.connection.mapStatus.get,
    ), defaultReducerMap, defaultState);




const defaultAuth = ({
    payload: {
        is_checked: false,
        ia_authenticated: false
    }
});

export const session = handleAction(Actions.data.session,
    (state, action) => {
        return action
    },
    defaultAuth
);


const defaultTarget=({
    payload: "defaultTarget"
});
export const target = handleAction(Actions.data.target,
    (state, action) => {
        return action
    },
    defaultTarget
);


const defaultArray = ({
    payload: []
});
const defaultObject = ({
    payload: {
    }
});
const defaultString = ({
    payload: ""
});

export const timePresetSets = handleAction(Actions.data.timePresetSets,
    (state, action) => {
        return action
    },
    defaultArray
);
export const openings = handleAction(Actions.data.openings,
    (state, action) => {
        return action
    },
    defaultArray
);
export const openingTimeAnnouncement = handleAction(Actions.data.openingTimeAnnouncement,
    (state, action) => {
        return action
    },
    defaultString
);
export const reserveTime = handleAction(Actions.data.reserveTime,
    (state, action) => {
        return action
    },
    defaultString
);
export const tickets = handleAction(Actions.data.tickets,
    (state, action) => {
        return action
    },
    defaultArray
);

export const account = handleAction(Actions.data.account,
    (state, action) => {
        return action
    },
    defaultObject
);

export const regions = handleAction(Actions.data.regions,
    (state, action) => {
        return action
    },
    defaultArray
);
export const tenants = handleAction(Actions.data.tenants,
    (state, action) => {
        return action
    },
    defaultArray
);
export const settings = handleAction(Actions.data.settings,
    (state, action) => {
        return action
    },
    defaultObject
);
export const timeGroups = handleAction(Actions.data.timeGroups,
    (state, action) => {
        return action
    },
    defaultArray
);


export const mapStatus = handleAction(Actions.data.mapStatus,
    (state, action) => {
        return action
    },
    defaultObject
);
export const mapLabels = handleAction(Actions.data.mapLabels,
    (state, action) => {
        return action
    },
    defaultObject
);




const defaultFailureObject = ({
    payload: {
        code: 4824,
        is_succeed: false,
        message: "",
    },
    meta: {
        isShow: false
    }
})
export const failure = handleAction(Actions.data.failure,
    (state, action) => {
        return action
    },
    defaultFailureObject
);



const defaultSuccessObject = ({
    payload: {
        message: ""
    },
    meta: {
        isShow: false
    }
});
export const success = handleAction(Actions.data.success,
    (state, action) => {
        return action
    },
    defaultSuccessObject
);

