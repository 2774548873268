import 'react-app-polyfill/ie11';
import 'url-search-params-polyfill';
import 'core-js/features/object';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {MuiThemeProvider} from '@material-ui/core';
import * as OverrideTheme from './OverrideTheme'
import * as reducers from './redux/reducers'
import {createBrowserHistory} from 'history'
import './scss/App.scss';
import './scss/index.scss';
import {Provider} from 'react-redux'
import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import {connectRouter, routerMiddleware, ConnectedRouter} from 'connected-react-router'
import {connection, dataStore} from './redux/middleware';
import {LocalizeProvider, localizeReducer} from 'react-localize-redux';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/ja';
import InitializeRouter from "./InitializeRouter";
import * as Helper from "./utils/Helper";

moment.locale("ja");


const key = Helper.getUniversityCustomScssCodeFromDomain();
import(`./scss/${key}/custom.scss`);

const materialTheme = OverrideTheme.getCustomTheme();
const history = createBrowserHistory({basename: "/client"});
const rootReducer = combineReducers({
    ...reducers,
    localize: localizeReducer
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
    connectRouter(history)(
        rootReducer
    ),
    composeEnhancer(
        applyMiddleware(
            routerMiddleware(history),
            connection,
            dataStore
        )
    )
);

function RootProvider() {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <MuiThemeProvider theme={materialTheme}>
                <Provider store={store}>
                    <LocalizeProvider store={store}>
                        <ConnectedRouter history={history}>
                            <InitializeRouter/>
                        </ConnectedRouter>
                    </LocalizeProvider>
                </Provider>
            </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    );
}


ReactDOM.render(
    <RootProvider/>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();