import React, {Component} from 'react';
import {renderToStaticMarkup} from 'react-dom/server';
import Paper from '@material-ui/core/Paper';
import BasePicker from 'material-ui-pickers/_shared/BasePicker';
import Calendar from 'material-ui-pickers/DatePicker/components/Calendar';
import {withLocalize, Translate} from 'react-localize-redux';
import 'scss/App.scss';
import jp from 'translations/jp.translations.json';
import en from 'translations/en.translations.json';
import Typography from "@material-ui/core/Typography/Typography";
import LoadingButton from "../../utils/LoadingButton";
import NumberPickerDialog from "../../utils/NumberPickerDialog";

class Dev extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: new Date()
        };
    }

    componentWillMount() {
        this.props.initialize({
            languages: [
                {name: "Japanese", code: "jp"},
                {name: "English", code: "en"}
            ],
            translation: jp,
            options: {renderToStaticMarkup}
        });
        this.props.setActiveLanguage("jp");
    }


    render() {
        console.log(process.env);

        const {selectedDate} = this.state;

        return (
            <div className="App">
                <div className={"Container"}>
                    <div className={"reserve-complete"}>
                        <Paper className={"paper"}>
                            <div>
                                <div className={"reserve-subject-cell"}>
                                    <Typography variant="subtitle2" color={"textSecondary"}>
                                        予約ユーザ
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        SibaService act3
                                    </Typography>
                                </div>
                                <div className={"reserve-subject-cell"}>
                                    <Typography variant="subtitle2" color={"textSecondary"}>
                                        リージョン
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        暮らしのなんちゃらなんちゃら　クソ長いセンスない名前
                                    </Typography>
                                </div>

                                <div className={"reserve-subject-cell"}>
                                    <Typography variant="subtitle2" color={"textSecondary"}>
                                        エリア
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        これもセンスない名前
                                    </Typography>
                                </div>
                            </div>

                        </Paper>
                    </div>
                    <NumberPickerDialog open={true}/>
                    <BasePicker value={selectedDate} onChange={this.handleDateChange}>
                        {
                            ({
                                 date,
                                 handleAccept,
                                 handleChange,
                                 handleClear,
                                 handleDismiss,
                                 handleSetTodayDate,
                                 handleTextFieldChange,
                                 pick12hOr24hFormat,
                             }) => (
                                <div>
                                    <Paper className={"calendarPaper"}
                                           style={{overflow: 'hidden', height: 800, width: 600}}>
                                        <Calendar date={date} onChange={handleChange}/>
                                    </Paper>
                                </div>
                            )
                        }
                    </BasePicker>

                    <Translate id="search"/>
                    <Translate id="cancel"/>

                    <p>
                        Edit <code>src/App.js</code> and save to reload.
                    </p>
                    <a
                        className="App-link"
                        href="https://reactjs.org"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Learn React
                    </a>
                </div>
            </div>
        );
    }
}

export default withLocalize(Dev);
